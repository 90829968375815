/* Container for the entire hero section */
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Use min-height instead of height to allow expansion if needed */
  background-color: rgb(23, 23, 23); /* Placeholder for background color */
  overflow: hidden; /* Prevents child elements from overflowing */
}

/* Container for the left side image */
.hero-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2rem; /* Ensures spacing between image and content */
}

/* Style for the image */
.hero-left img {
  flex: 1;
  max-width: 100%;
  height: auto;
}

.hero-right {
  flex: 1;
  display: flex;
  align-items: center; /* This will vertically center the content on the hero-right */
  justify-content: center; /* This will horizontally center the content on the hero-right */
  text-align: left;
}

/* Style for the main heading in the content */
.hero-heading {
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 0.5rem;
}

/* Style for the subtext in the content */
.hero-subtext {
  font-size: 1.25rem;
  color: #fff;
  margin-bottom: 2rem;
}

/* Button styles */
.hero-cta {
  background-color: #ff6c00; /* Brand's primary color */
  color: #fff;
  padding: 1rem 2rem;
  font-size: 1.25rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.hero-cta:hover {
  background-color: #e65c00; /* Darker shade for hover effect */
}

/* Style for additional info like 'Free Shipping' */
.hero-info {
  font-size: 1rem;
  color: #fff;
  margin-top: 2rem;
  font-weight: bold;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    background-size: cover;
    background-position: center;
    background-color: rgb(25, 25, 25);
  }

  .hero-left,
  .hero-right {
    width: 100%;
    position: relative; /* Ensure .hero-right can be absolutely positioned relative to .hero */
  }

  .hero-left img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; /* Ensure the image stays behind the text */
    filter: blur(1px);
  }

  .hero-right {
    z-index: 2; /* Ensures text content is above the image */
    color: #fff; /* Ensure text color is visible on top of the image */
    padding: 1rem; /* Add some padding around the text for readability */
    position: absolute;
    top: 0; /* Align the top of the container with the parent */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content vertically */
    height: 100%; /* Match the height of the .hero-left to cover the image */
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Optional: Overlay to improve text readability */
  }

  .hero-content {
    padding: 1rem;
  }

  /* Adjust the sizes for smaller screens */
  .hero-heading {
    font-size: 1.5rem;
  }

  .hero-subtext {
    font-size: 1rem;
  }

  .hero-cta {
    font-size: 0.875rem;
    padding: 0.75rem 1.5rem;
  }

  .hero-info {
    font-size: 0.875rem;
  }
}
