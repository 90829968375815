.sign-up-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem; /* Increased padding for larger area */
  background-color: #fff; /* or any color that fits your design */
  color: #333;
}

.sign-up-section h2 {
  margin-bottom: 1.5rem; /* Increased margin for more space */
  text-align: center;
  font-size: 3rem; /* Larger font size for the heading */
  color: black;
}

.sign-up-section h3 {
  margin-bottom: 1.5rem; /* Increased margin for more space */
  text-align: center;
  font-size: 1.5rem; /* Larger font size for the heading */
  color: #333;
}

.sign-up-form {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem; /* Increased margin for more space */
  width: 100%; /* Full width to maximize space */
  max-width: 500px; /* Larger max-width for larger form */
}

.sign-up-form input {
  padding: 0.75rem 1rem; /* Increased padding for a larger input field */
  font-size: 1.25rem; /* Larger font size for input text */
  border: 1px solid #ccc; /* or any color that fits your design */
  border-radius: 4px;
  flex-grow: 1; /* Input field grows to fill space */
}

.sign-up-button {
  padding: 0.75rem 1rem; /* Increased padding for a larger button */
  font-size: 1.5rem; /* Larger font size for button text */
  color: #fff;
  background-color: #ff6c00; /* or any color that fits your design */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap; /* Prevents text wrapping on smaller screens */
}

.sign-up-button:hover {
  background-color: #e65c00; /* Slightly darker shade for hover effect */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sign-up-form {
    flex-direction: column;
    align-items: center;
  }

  .sign-up-form input,
  .sign-up-button {
    width: 100%;
    max-width: 300px; /* Ensure the form fields don't stretch too much */
  }

  .sign-up-button {
    margin-top: 1rem; /* Add space between input and button on mobile */
  }
}
